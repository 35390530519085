export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async updateValidez({ commit }, payload) {
    try {
      const response = await this.$axios.patch(
        `/subalcances/${payload.uuid}`,
        {
          valid: payload.validez,
        },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSubalcances({ rootState }, params) {
    let queryParams = []

    if (params?.search) {
      queryParams.push(`nombre=${encodeURIComponent(params.search)}`)
    }
    if (params?.alcance) {
      queryParams.push(`alcance.uuid=${params.alcance}`)
    }
    if (params?.page) {
      queryParams.push(`page=${params.page}`)
    }
    if (params?.itemsPerPage) {
      queryParams.push(`itemsPerPage=${params.itemsPerPage}`)
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    const res = await this.$axios.get(`subalcances${queryString}`)

    if (res?.data && res.data['hydra:member']) {
      return {
        items: res.data['hydra:member'],
        total: res.data['hydra:totalItems'],
      }
    } else {
      return {
        items: [],
        total: 0,
      }
    }
  },
  async list({ rootState }, params) {
    rootState.loading = true
    let res = null
    if (params?.nombre) {
      res = await this.$axios.get(`subalcances?nombre=${params.nombre}`)
    }
    if (params?.alacance) {
      res = await this.$axios.get(`subalcances?alcance.uuid=${params.alcance}`)
    } else {
      res = await this.$axios.get('subalcances')
    }
    rootState.loading = false

    if (res?.data && res.data['hydra:member']) {
      return res.data['hydra:member']
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async getProductos({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}/productos`)
    rootState.loading = false
    if (res) return res.data
  },

  async clone({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.post(`subalcances/${uuid}/clone`, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    })
    rootState.loading = false
    if (res) return res.data
  },

  async delete({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.delete(`subalcances/${uuid}`)
    rootState.loading = false
    if (res) return res
  },

  async incrementVersion({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}/increment-version`)
    rootState.loading = false
    if (res) return res.data
  },

  async updateEstado({ rootState }, payload) {
    rootState.loading = true
    const res = await this.$axios.patch(
      `subalcances/${payload.uuid}`,
      {
        activo: payload.activo,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )
    rootState.loading = false
    if (res) return res.data
  },
}
